import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Star } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Gallery } from './Gallery'
import { Info } from './Info'

export interface Props {
  bookingButtonURL?: string
  description?: string
  images?: ImageProps[]
  highlight?: string
  languageCode: string
  pax?: string
  size?: string
  subtitle?: string
  title?: string
}

export const Room = memo(function Room({
  bookingButtonURL,
  description,
  images,
  highlight,
  languageCode,
  pax,
  size,
  subtitle,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head dial={4} row wrap>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {pax || size ? (
            <FadeInUp>
              <Info pax={pax} size={size} />
            </FadeInUp>
          ) : null}

          {highlight ? (
            <Media lessThan="desktopSmall">
              <Highlight dial={4} row>
                <Star />

                {highlight}
              </Highlight>
            </Media>
          ) : null}
        </Head>

        <Wrapper row stretch wrap>
          <LeftSide dial={4}>
            {subtitle ? (
              <FadeInUp>
                <Subtitle>{subtitle}</Subtitle>
              </FadeInUp>
            ) : null}

            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}

            {bookingButtonURL ? (
              <FadeInUp>
                <CTA
                  label={useVocabularyData('book', languageCode)}
                  URL={bookingButtonURL}
                  rel="noopener"
                  target="_blank"
                  variant="invert"
                />
              </FadeInUp>
            ) : null}
          </LeftSide>

          <RightSide>
            {images ? <Gallery images={images} /> : null}

            {highlight ? (
              <Media greaterThanOrEqual="desktopSmall">
                <Highlight dial={4} row>
                  <Star />

                  {highlight}
                </Highlight>
              </Media>
            ) : null}
          </RightSide>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div``

const Head = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 4.4375rem 3.75rem 4.25rem;

  > div {
    width: 50%;
  }

  @media (max-width: 1199px) {
    padding: 2.5rem 1.875rem;

    > div {
      width: 100%;
    }
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  padding: 3.75rem 9.444vw;

  @media (max-width: 1199px) {
    width: 100%;
    order: 2;
    padding: 2.6875rem 1.875rem 3.75rem;

    > div {
      width: 100%;
    }
  }
`

const Subtitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 1.875rem;
`

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.6875rem;
`

const CTA = styled(Button)`
  margin-top: 3rem;

  @media (max-width: 1199px) {
    display: block;
  }
`

const RightSide = styled.div`
  width: 50%;
  height: auto;

  @media (min-width: 1440px) {
    aspect-ratio: 1.333;
  }

  @media (max-width: 1199px) {
    aspect-ratio: 1.3;
    width: 100%;
    height: auto;
  }
`

const Highlight = styled(FlexBox)`
  height: 5.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  padding: 0 1.875rem;

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-right: 0.75rem;
  }

  @media (max-width: 1199px) {
    height: auto;
    background: none;
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 0.75rem;
    padding: 0;

    svg {
      display: none;
    }
  }
`
